import { ScrollTrigger } from "gsap/ScrollTrigger.js";

export default class Modal {
  constructor() {
    this.modalWrappers = document.querySelectorAll(".modal-wrapper");
    this.modal;
    this.events();
  }

  events() {
    if (this.modalWrappers) {
      this.modalWrappers.forEach((modalWrapper) => {
        const myModal = modalWrapper.querySelector(".my-modal");
        const openButton = modalWrapper.querySelector("#open-modal");
        const closeButton = modalWrapper.querySelector("#close-modal");
        openButton.onclick = () => this.handleOpenButton(myModal);
        document.onclick = (e) => this.handleOutsideClick(e);
        closeButton.onclick = () => this.handleCloseButton();
      });
    }
  }

  handleOutsideClick = (e) => {
    if (e.target.classList.contains("my-modal")) {
      this.handleCloseButton();
    }
  };

  handleOpenButton = (modal) => {
    this.modal = modal;
    this.modal.parentElement.classList.add("relative", "z-max");
    ScrollTrigger.normalizeScroll(false);
    history.pushState({ modalState: true }, "");
    document.body.style.overflowY = "hidden";
    window.addEventListener("popstate", this.handleCloseButton);
    this.modal.style.display = "flex";
    setTimeout(() => {
      this.modal.classList.add("animate-in");
    }, 10);
  };

  handleCloseButton = () => {
    this.handleModalState(this.modal);
    document.body.style.overflowY = "visible";
    ScrollTrigger.normalizeScroll(true);
    window.removeEventListener("popstate", this.handleCloseButton);
  };

  handleModalState = () => {
    this.modal.parentElement.classList.remove("relative", "z-max");
    this.modal.classList.remove("animate-in");
    setTimeout(() => {
      this.modal.style.display = "none";
    }, 400);
  };
}
