import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js"

export default class AnimationHeader{
    constructor(){
        this.scrollUpInfo = document.getElementById('scroll-up-info')
        this.navBar = document.getElementById("navbar")
        this.isInQueue = false
        this.go = false;
        this.timeoutId;
        this.isHomepage = document.querySelector("body.home");
        this.atfSection = document.querySelector(".atf")
        this.scrollStart = this.atfSection ? this.atfSection.offsetHeight + 50 : '150';
        this.events();
      }
      
      events(){
        
        gsap.registerPlugin(ScrollTrigger)
        this.initAnimHeader()
      
    }

     
initAnimHeader = ()=> {
  // const showAnim = gsap.from(this.navBar, { 
  //   yPercent: -100,
  //   paused: true,
  //   duration: 0.4
  // }).progress(1);


    ScrollTrigger.create({
      start: `${this.scrollStart}px top`,
      end: 99999,
      onUpdate: (self) => {
        // this.go ? self.direction === -1 ? (showAnim.play(), this.hideScrollUp()) : (showAnim.reverse(), this.revealScrollUp()) : null;
        this.go ? self.direction === -1 ? this.hideScrollUp() : this.revealScrollUp() : null;
        //prevent on new page load that navbar hides immediately
        this.go = true;
      }
    });
}

  revealScrollUp = ()=>{
    this.navBar.classList.add("hide-navbar")
    if(!this.isInQueue){
      this.isInQueue = true
      this.timeoutId = setTimeout(()=>{
        this.scrollUpInfo.classList.add("show")
        this.isInQueue = false
      }, 2000)
    }

  }
  hideScrollUp = ()=>{
    this.navBar.classList.remove("hide-navbar")
    this.isInQueue = false
    clearTimeout(this.timeoutId);
    this.scrollUpInfo.classList.remove("show")
  }


  
}
    