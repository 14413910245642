import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

export default class AnimationScrubGallery {
  constructor() {
    this.events();
  }

  events() {
    gsap.registerPlugin(ScrollTrigger);
    if (document.querySelector(".scrub-gallery")) this.initScrubGallery();
  }

  initScrubGallery = () => {
    gsap.utils.toArray(".scrub-gallery section").forEach((section, index) => {
      const wrapper = section.querySelector(".translateX");

      if (wrapper) {
        const isText = section.classList.contains("demo-text");

        const windowWrapper = section.parentElement;
        const numberOfChildren = wrapper.children.length;

        // const [x, xEnd] = (index % 2) ? ['10%', (wrapper.scrollWidth - section.offsetWidth) * -1] : [wrapper.scrollWidth * -1, '0%'];
        const [xStart, xEnd] =
          index % 2 == 0
            ? [
                isText
                  ? windowWrapper.offsetWidth
                  : numberOfChildren == 1
                  ? section.offsetWidth * 0.8
                  : section.offsetWidth * -1.2,
                isText
                  ? -section.offsetWidth
                  : (wrapper.scrollWidth - section.offsetWidth) * -1.5,
              ]
            : [
                isText ? -section.offsetWidth : wrapper.scrollWidth * -1.2,
                isText ? windowWrapper.offsetWidth : section.offsetWidth * 1.7,
              ];

        gsap.fromTo(
          wrapper,
          { x: xStart },
          {
            x: xEnd,
            ease: "none",
            scrollTrigger: {
              trigger: section,
              scrub: 1,
            },
          }
        );
      }
      const gallery = section.querySelector(".scrub-gallery-scroll");
      if (gallery) {
        const scrollWidth = gallery.scrollWidth;
        gsap.timeline({
          scrollTrigger: {
            trigger: gallery,
            start: `${gallery.offsetHeight * 0.8} bottom`,
            end: `${gallery.offsetHeight + 200} top`,
            toggleActions: "restart none none reverse",
            scrub: 0.8,
            onUpdate: (self) => {
              gallery.scrollLeft = scrollWidth * self.progress;
            },
          },
        });
      }
    });
  };
}
