export default class ExtraMenuNavBar{
    constructor(){
        this.extraMenus = document.querySelectorAll(".extra-menu");
        this.currentExtraMenu = null;
        this.events();
      }
      
      events(){
        if(this.extraMenus.length > 0) {
          this.extraMenus.forEach(extraMenu => extraMenu.addEventListener("click", this.handleClick))
        }
    }

      
    clickOutside = (e)=>{
        this.currentExtraMenu.classList.remove("active")
        document.removeEventListener('click', this.clickOutside)
        // const withinBoundaries = event.composedPath().includes(extraMenu) 
        // if(!withinBoundaries) {
        // }
    }


    handleClick = (e)=>{
      e.stopPropagation();
      
      //close open currentExtraMenu if another one is opened
      if(this.currentExtraMenu != null && this.currentExtraMenu != e.currentTarget){
        this.clickOutside();
      }
      this.currentExtraMenu = e.currentTarget
      const hasATag = e.currentTarget.classList.contains("extra-menu-item")
        //if extraMenu contains an a-tag is clicked, do nothing
        if(hasATag){
          return;
        }
        //if extraMenu button is clicked
        if(e.currentTarget.classList.contains("active"))
        {
          e.currentTarget.classList.remove("active")
          document.removeEventListener('click', this.clickOutside)
          return;
        } 
        //this runs if extraMenu is clicked, but it wasnt active (opened)
          e.currentTarget.classList.add("active")
          document.addEventListener('click', this.clickOutside)
      }

}

    