import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

export default class AnimationMenu {
  constructor() {
    this.navBar = document.getElementById("navbar");
    this.navbarSticky = document.getElementById("navbar-sticky");
    this.menuItems = document.querySelectorAll(
      "#navbar-menu-content > li, #navbar-menu-content .social-submenu li"
    );
    this.allLinksInMenu = document.querySelectorAll("#navbar-menu-content a");
    this.hamburger = document.getElementById("hamburger");
    this.hamburgerLines = document.getElementById("hamburger-icon");
    this.goToParentButtons = document.querySelectorAll("#go-to-parent");
    this.navbarMenuContent = document.getElementById("navbar-menu-content");
    this.currentSubview = this.navbarMenuContent;
    this.yGetter;
    this.scaleGetter;
    this.linksInMenu = Array.from(this.allLinksInMenu);
    this.allLinksWithHashtag = this.linksInMenu.filter((link) =>
      link.href.includes("#")
    );
    this.t;
    this.events();
  }

  events() {
    this.setUpGetters();
    this.initiateMenu();
  }

  setUpGetters = () => {
    this.t = (y, scale) => {
      const getter = gsap.getProperty("#logo");
      this.yGetter = getter("y");
      this.scaleGetter = getter("scale");
      gsap.to("#logo", { y, scale, duration: 0.4 });
    };
  };
  initiateMenu = () => {
    this.allLinksWithHashtag.forEach((link) => {
      link.addEventListener("click", () => {
        this.hamburger.click();

        document
          .querySelector("#ajax-loading-screen")
          .classList.remove("loaded");
        document
          .querySelector("#ajax-loading-screen")
          .classList.remove("is-hidden");
        document.querySelector("#ajax-loading-screen").classList.add("loaded");
        this.t(0, 1);
        setTimeout(() => {
          document
            .querySelector("#ajax-loading-screen")
            .classList.add("is-hidden");
        }, 1000);
      });
    });

    let subMenuItems = [];
    this.hamburger.addEventListener("click", (e) => {
      this.navBar.classList.remove("hide-navbar");
      this.hamburgerLines.classList.toggle("open");
      if (this.navbarSticky.classList.contains("collapsed")) {
        document.body.style.overflow = "hidden";
        this.navbarMenuContent.style.overflow = "scroll";
        openMenu();
        this.t(0, 1);
      } else {
        closeSubMenu();
        closeMenu();
        this.t(this.yGetter, this.scaleGetter);
      }
      this.navbarSticky.classList.toggle("collapsed");
      this.navbarMenuContent.classList.toggle("fadeIn");
    });

    this.goToParentButtons.forEach((gtpButton) =>
      gtpButton.addEventListener("click", (e) => {
        e.stopPropagation();
        this.currentSubview.classList.add("preventFlicker");
        this.currentSubview.classList.remove("slideUp");
        closeSubMenu();
        // setTimeout(()=>{
        openMenu();
        // },200)
      })
    );

    const checkForSubMenu = (e) => {
      if (e.currentTarget.classList.contains("menu-item-has-children")) {
        e.preventDefault();
        this.currentSubview = e.currentTarget;
        this.navbarMenuContent.classList.add("subview");
        e.currentTarget.classList.add("subviewopen");
        this.menuItems.forEach((menuItem, index) => {
          if (!menuItem.classList.contains("subviewopen")) {
            menuItem.classList.remove("slideUp");
          }
        });
        this.currentSubview.classList.add("grow-effect");
        this.subMenuItems = e.currentTarget.querySelectorAll(".sub-menu li");
        this.subMenuItems.forEach((subMenuItem, index) => {
          let timeout = index * 50 + 200;
          setTimeout(() => {
            subMenuItem.classList.add("slideUp");
          }, timeout);
        });
      }
    };

    const openMenu = () => {
      ScrollTrigger.normalizeScroll(false);
      // if(lightbox) lightbox.close();
      //remove subview so submenu is not overlaying
      this.menuItems.forEach((menuItem, index) => {
        let timeout = index * 50 + 200;
        // const href = menuItem.getElementsByTagName('a')[0].href;
        setTimeout(() => {
          menuItem.classList.add("slideUp");
        }, timeout);
        menuItem.addEventListener("click", (e) => checkForSubMenu(e), {
          once: true,
        });
      });
      this.currentSubview.classList.remove("subviewopen");
      this.navbarMenuContent.classList.remove("subview");
    };

    const closeSubMenu = () => {
      this.currentSubview.classList.remove("grow-effect");
      subMenuItems.forEach((subMenuItem, index) => {
        subMenuItem.classList.remove("slideUp");
      });
      // currentSubview.classList.remove("slideUp")
    };

    const switchMenu = () => {
      this.menuItems.forEach((menuItem, index) => {
        let timeout = (this.menuItems.length - index) * 50 + 200;
        menuItem.classList.remove("slideUp");
      });
    };

    const closeMenu = () => {
      switchMenu();
      setTimeout(() => {
        this.navbarMenuContent.classList.remove("subview");
      }, 500);
      ScrollTrigger.normalizeScroll(true);
      document.body.style.overflow = "auto";
      this.navbarMenuContent.style.overflow = "auto";
    };
  };
}
