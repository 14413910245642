import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

export default class LaptopPhoneSlider {
  constructor() {
    this.isHomepage = document.querySelector("body.home");
    this.device;
    // this.screens = document.querySelector(".screens");
    this.screen;
    this.events();
  }

  events() {
    if (this.isHomepage) this.initiateSlider();
  }

  initiateSlider = () => {
    gsap.defaults({ ease: "linear" });
    gsap.registerPlugin(ScrollTrigger);
    // gsap.defaults({ ease: "inOut" });

    let mm = gsap.matchMedia();

    mm.add(
      {
        isMobile: "(max-width: 767px)",
        isDesktop: "(min-width: 767px)",
      },
      (context) => {
        let { isMobile, isDesktop } = context.conditions;
        isMobile
          ? ((this.device = document.querySelector(".phone-wrapper")),
            (this.screen = document.querySelectorAll(
              ".phone-wrapper .app_screen"
            )),
            ScrollTrigger.normalizeScroll({ allowNestedScroll: false }))
          : ((this.device = document.querySelector(".laptop-wrapper")),
            (this.screen = document.querySelectorAll(
              ".laptop-wrapper .app_screen"
            )));
        let sections = gsap.utils.toArray(this.screen);

        // gsap.timeline({scrollTrigger:{
        //   trigger:".footer",
        //   // start: "300px bottom",
        //   start: "top bottom",
        //   end:"300px top",
        //   scrub: true,
        // }})

        // .to(navbarMenuContent, { opacity: 0.9}, 0)
        // .fromTo("#logo", {y:0,scale:1}, { y: window.innerHeight/2.2,  scale: isMobile ? 2 : 3}, 0)
        //  gsap.to(".wp-block-cover", {opacity:1, delay: isOnTop ? 1.5 : 0})
        gsap
          .timeline({
            scrollTrigger: {
              trigger: this.device,
              start: "center center",
              //start: -navBar.offsetHeight + "px" + " top",
              end: `${isMobile ? "=+2300px" : "=+2000px"} center`,
              toggleActions: "restart none none reverse",
              scrub: 1,
              pin: true,
              pinSpacer: true,
              markers: false,
              // onUpdate: (self) => console.log("progress", self.progress),

              snap: isMobile
                ? false
                : {
                    snapTo: 1 / (sections.length - 1),
                    directional: false,
                  },

              //markers:true,
            },
          })

          .to(sections, { yPercent: -100 * (sections.length - 1) }, 0);
      }
    );
  };
}
