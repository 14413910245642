export default class AnimationAccordion{
    constructor(){
        this.accordionHeader = document.querySelectorAll(".accordion-header");
        this.events();
      }
      
      events(){
      if(this.accordionHeader) this.initAccordion()
    }

     
initAccordion = ()=> {
  this.accordionHeader.forEach((header) => {
    header.addEventListener("click", function () {
        const accordionContent = header.parentElement.querySelector(".accordion-content");
        let accordionMaxHeight = accordionContent.style.maxHeight;
  
        // Condition handling
        if (accordionMaxHeight == "0px" || accordionMaxHeight.length == 0) {
        accordionContent.style.maxHeight = `${accordionContent.scrollHeight + 32}px`;
        header.querySelector(".dashicons-before").classList.add("transition");
        header.querySelector(".dashicons-before").classList.add("open");
        setTimeout(() => {
          header.querySelector(".dashicons-before").classList.remove("transition");
        }, 300);
        header.parentElement.classList.add("bg-gray-100");
        } else {
        accordionContent.style.maxHeight = `0px`;
        header.querySelector(".dashicons-before").classList.add("transition");
        header.querySelector(".dashicons-before").classList.remove("open");
        setTimeout(() => {
          header.querySelector(".dashicons-before").classList.remove("transition");
        }, 300);
        header.parentElement.classList.remove("bg-gray-100");
        }
    });
    });
}

}

    