import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

export default class BugAnimation {
  constructor() {
    this.isHomepage = document.querySelector("body.home");
    this.bug = document.getElementById("eHptabmSMXs1");
    // this.screens = document.querySelector(".screens");

    this.events();
  }

  events() {
    if (this.isHomepage) this.initiateBugAnimation();
  }

  initiateBugAnimation = () => {
    // gsap.defaults({ ease: "linear" });
    gsap.registerPlugin(ScrollTrigger);

    let mm = gsap.matchMedia();

    mm.add(
      {
        isMobile: "(max-width: 442px)",
        isTablet: "(max-width: 767px)",
        isDesktop: "(min-width: 767px)",
        isGigantic: "(min-width: 1600px)",
      },
      (context) => {
        let { isMobile, isDesktop, isTablet, isGigantic } = context.conditions;
        let viewport = window.innerWidth;
        // gsap.timeline({scrollTrigger:{
        //   trigger:".footer",
        //   // start: "300px bottom",
        //   start: "top bottom",
        //   end:"300px top",
        //   scrub: true,
        // }})
        const windowWidth = window.innerWidth;
        const percentageBugToWindow =
          (windowWidth / this.bug.clientWidth) * 100;
        console.log(
          "🚀 ~ file: BugAnimation.js:42 ~ BugAnimation ~ percentageBugToWindow:",
          percentageBugToWindow
        );
        // .to(navbarMenuContent, { opacity: 0.9}, 0)
        // .fromTo("#logo", {y:0,scale:1}, { y: window.innerHeight/2.2,  scale: isMobile ? 2 : 3}, 0)
        //  gsap.to(".wp-block-cover", {opacity:1, delay: isOnTop ? 1.5 : 0})
        gsap
          .timeline({
            scrollTrigger: {
              trigger: this.bug,

              start: `${isMobile ? "=-300" : "top"} center`,
              //start: -navBar.offsetHeight + "px" + " top",
              end: `=+400 center`,
              toggleActions: "restart none none reverse",
              scrub: isDesktop ? 1 : 0.3,
              onScrubComplete: () => window.SVGATOR.pause(),
              onUpdate: ({ direction }) => {
                !window.SVGATOR._running && window.SVGATOR.play(),
                  gsap.to(this.bug, {
                    scaleX: () => (direction === 1 ? 1 : -1),
                    overwrite: "auto",
                    duration: 0.1,
                  });
              },
              pin: false,
              pinSpacer: false,
              // onUpdate: (self) => console.log("progress", self.progress),
            },
          })

          .fromTo(
            this.bug,
            {
              xPercent: percentageBugToWindow / 1.5,
            },
            {
              xPercent: -percentageBugToWindow / 1.5,
            },
            0
          );
      }
    );
  };
}
