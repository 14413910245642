import "./index.css";
import Prism from "prismjs";

// import Person from "./scripts/Person"
// import ExampleReactComponent from "./scripts/ExampleReactComponent"
import AnimationLogo from "./scripts/AnimationLogo";
import LaptopPhoneSlider from "./scripts/LaptopPhoneSlider";
import AnimationMenu from "./scripts/AnimationMenu";
import AnimationAccordion from "./scripts/AnimationAccordion";
import AnimationHeader from "./scripts/AnimationHeader";
import BugAnimation from "./scripts/BugAnimation";
import AnimationScrubGallery from "./scripts/AnimationScrubGallery";
import Modal from "./scripts/Modal";
import ConsentHelper from "./scripts/ConsentHelper";
import ExtraMenuNavBar from "./scripts/ExtraMenuNavbar";
import GLightbox from "glightbox";
import AOS from "aos";

import Glide from "@glidejs/glide";
import SlideShowScrubber from "./scripts/SlideShowScrubber";
window.Glide = Glide;
window.GLightbox = GLightbox;

const isMobile = window.matchMedia("(max-width: 767px)").matches;
window.isMobile = isMobile;

AOS.init({
  once: true,
  offset: window.innerHeight > 800 ? 400 : window.innerHeight < 400 ? 100 : 200, // offset (in px) from the original trigger point
});
const isHomepage = document.querySelector("body.home");
// const person1 = new Person("Brad")
// if (document.querySelector("#render-react-example-here")) {
//   ReactDOM.render(<ExampleReactComponent />, document.querySelector("#render-react-example-here"))
// }
// When the user scrolls down 80px from the top of the document, resize the navbar's padding and the logo's font size
let navBar;

const loadingScreen = document.getElementById("ajax-loading-screen");

const showContent = () => {
  navBar = document.getElementById("navbar");
  if (!isHomepage)
    document.querySelector(".wp-site-blocks").style.marginTop =
      navBar.offsetHeight + "px";
  //document.body.style.marginTop = navBar.offsetHeight +"px";
  loadingScreen.classList.add("loaded");
  setTimeout(() => {
    setTimeout(function () {
      loadingScreen.classList.add("is-hidden");
      const isOnTop = window.pageYOffset == 0;
      window.isOnTop = isOnTop;
    }, 1000);
    document.querySelector("body").classList.remove("no-scroll");
    setTimeout(() => {
      new AnimationHeader();
      new AnimationLogo();
      new AnimationMenu();
      new AnimationAccordion();
      new ExtraMenuNavBar();
      new AnimationScrubGallery();
      new Modal();
      new ConsentHelper();
      new LaptopPhoneSlider();
      new SlideShowScrubber();
      new BugAnimation();
      injectCSS();
      appendSpeechBubble();
      addAosToWPClasses();
      addToWpFormsButton();
    }, 500);
  }, 500);
};

function addToWpFormsButton() {
  document.querySelector(".wpforms-submit-container") ??
    document
      .querySelector(".wpforms-submit-container")
      .classList.add("bg-effect");
}

function addAosToWPClasses() {
  const aosElements = document.querySelectorAll(".aos");
  const aosElementsLeft = document.querySelectorAll(".aos-left");
  const aosElementsRight = document.querySelectorAll(".aos-right");
  aosElements.forEach((element) => {
    element.dataset.aos = "fade-up";
  });

  aosElementsLeft.forEach((element) => {
    element.dataset.aos = "fade-left";
  });

  aosElementsRight.forEach((element) => {
    element.dataset.aos = "fade-right";
  });
}

function appendSpeechBubble() {
  // Create a new SVG element based on the provided SVG XML
  const svgSpeechBubble = `<svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 85.704 64.542" id="Layer_1" xml:space="preserve">
  <defs id="defs8" />
  <g transform="matrix(-0.9486962, 0, 0, 0.9486962, 85.704, 1.8361818)" id="g3">
    <path d="M 45.673,0 C 67.781,0 85.703,12.475 85.703,27.862 C 85.703,43.249 67.781,55.724 45.673,55.724 C 38.742,55.724 32.224,54.497 26.539,52.34 C 15.319,56.564 0,64.542 0,64.542 C 0,64.542 9.989,58.887 14.107,52.021 C 15.159,50.266 15.775,48.426 16.128,46.659 C 9.618,41.704 5.643,35.106 5.643,27.862 C 5.643,12.475 23.565,0 45.673,0 M 45.673,2.22 C 24.824,2.22 7.862,13.723 7.862,27.863 C 7.862,34.129 11.275,40.177 17.472,44.893 L 18.576,45.734 L 18.305,47.094 C 17.86,49.324 17.088,51.366 16.011,53.163 C 15.67,53.73 15.294,54.29 14.891,54.837 C 18.516,53.191 22.312,51.561 25.757,50.264 L 26.542,49.968 L 27.327,50.266 C 32.911,52.385 39.255,53.505 45.673,53.505 C 66.522,53.505 83.484,42.002 83.484,27.862 C 83.484,13.722 66.522,2.22 45.673,2.22 L 45.673,2.22 z " id="path5" />
  </g>
</svg>`;

  const svgSpeakerOff = `<svg id="speaker-off" class="rounded-full bg-white shadow-[0_3px_4px_4px_rgba(0,0,0,.1)]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 3L7 8H5C3.89543 8 3 8.89543 3 10V14C3 15.1046 3.89543 16 5 16H7L13 21V3Z" fill="#000000"/>
<path d="M16 8.99998C16.5 9.49998 17 10.5 17 12C17 13.5 16.5 14.5 16 15M13 3L7 8H5C3.89543 8 3 8.89543 3 10V14C3 15.1046 3.89543 16 5 16H7L13 21V3Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

  const svgSpeakerOn = `<svg id="speaker-on" class="rounded-full bg-white shadow-[0_3px_4px_4px_rgba(0,0,0,.1)]" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" shape-rendering="geometricPrecision" text-rendering="geometricPrecision"><path d="M13,3L7,8h-2c-1.10457,0-2,.89543-2,2v4c0,1.1046.89543,2,2,2h2l6,5v-18Z" /><path d="M16,8.99998C16.5,9.49999,17,10.5,17,12s-.5,2.5-1,3m1.403141-8.780105c1.5,1.5,2.324608,3.780105,2.324608,5.780105s-.824608,4.280105-2.324608,5.780105M13,3L7,8h-2c-1.10457,0-2,.89543-2,2v4c0,1.1046.89543,2,2,2h2l6,5v-18Z" fill="none" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M18.425174,20.406025q3.976694-4.462836,3.976694-8.462836t-3.976694-8.583515" transform="translate(.000001 0.056811)" fill="none" stroke="#000" stroke-width="1.4" stroke-linecap="round"/></svg>`;

  const divElement = document.createElement("div");
  divElement.classList.add("absolute", "top-20", "-left-16", "md:w-36", "w-32");
  divElement.dataset.aos = "fade-up";
  divElement.dataset.aosOffset = "350";

  divElement.insertAdjacentHTML(
    "beforeend",
    `<button class='absolute inset-0 m-auto w-10 h-10 bottom-4 md:bottom-5' id='playButton'></button><audio id='audioPlayer' src='${
      pixelfolioSiteData.siteUrl +
      `/wp-content/themes/pixelfolio/assets/audio/greetings-${pixelfolioSiteData.languageCode}.mp3`
    }'></audio>`
  );

  // Parse the SVG XML and append it to the div
  const parser = new DOMParser();
  const svgDocumentBubble = parser.parseFromString(
    svgSpeechBubble,
    "image/svg+xml"
  );
  divElement.appendChild(svgDocumentBubble.documentElement);

  // Find the .foto element and append the div containing the SVG to it
  const fotoElement = document.querySelector(".foto");
  if (fotoElement) {
    fotoElement.appendChild(divElement);
  }

  // JavaScript code to play audio when the button is clicked
  const playButton = divElement.querySelector("#playButton");
  const audioPlayer = divElement.querySelector("#audioPlayer");

  const svgDocumentSpeakerOn = parser.parseFromString(
    svgSpeakerOn,
    "image/svg+xml"
  );
  const svgDocumentSpeakerOff = parser.parseFromString(
    svgSpeakerOff,
    "image/svg+xml"
  );

  playButton.appendChild(svgDocumentSpeakerOn.documentElement);
  playButton.appendChild(svgDocumentSpeakerOff.documentElement);

  const speakerOn = playButton.querySelector("#speaker-on");
  const speakerOff = playButton.querySelector("#speaker-off");

  // Add a listener for the "pause" event
  audioPlayer.addEventListener("ended", () => {
    // The audio is paused
    speakerOn.style.display = "none";
    speakerOff.style.display = "inline";
    console.log("Audio is paused");
  });

  playButton.addEventListener("click", () => {
    if (audioPlayer.paused || audioPlayer.ended) {
      console.log("Audio is playing");
      audioPlayer.play();
      speakerOn.style.display = "inline";
      speakerOff.style.display = "none";
    } else {
      audioPlayer.pause();
      speakerOn.style.display = "none";
      speakerOff.style.display = "inline";
    }
  });
}

function injectCSS() {
  // Create a <style> element
  var styleElement = document.createElement("style");
  styleElement.type = "text/css";

  // Define your CSS rules
  var css = `
  #p-1 {
    animation: strokeOffset 0.2s ease-in-out forwards 0.4s;
  }
  
  #p-2 {
    animation: strokeOffset 0.2s ease-in-out forwards 0.6s;
  }
  #i-1st {
    animation: strokeOffset 0.2s ease-in-out forwards 0.8s;
  }
  #x-1 {
    animation: strokeOffset 0.2s ease-in-out forwards 1s;
  }
  #x-2 {
    animation: strokeOffset 0.2s ease-in-out forwards 1.2s;
  }
  #e-1 {
    animation: strokeOffset 0.2s ease-in-out forwards 1.4s;
  }
  #e-2 {
    animation: strokeOffset 0.2s ease-in-out forwards 1.6s;
  }
  #l-1st {
    animation: strokeOffset 0.2s ease-in-out forwards 1.8s;
  }
  #f-1 {
    animation: strokeOffset 0.2s ease-in-out forwards 2s;
  }
  #f-2 {
    animation: strokeOffset 0.2s ease-in-out forwards 2.2s;
  }
  #f-3 {
    animation: strokeOffset 0.2s ease-in-out forwards 2.4s;
  }
  #o-1st {
    animation: strokeOffset 0.2s ease-in-out forwards 2.6s;
  }
  #l-2nd {
    animation: strokeOffset 0.2s ease-in-out forwards 2.8s;
  }
  #i-2nd {
    animation: strokeOffset 0.2s ease-in-out forwards 3s;
  }
  #o-2nd {
    animation: strokeOffset 0.2s ease-in-out forwards 3.2s;
  }
      
  @keyframes strokeOffset {
    to {
      stroke-dashoffset: 0;
    }
  }
  `;

  // Set the CSS content
  if (styleElement.styleSheet) {
    // For IE
    styleElement.styleSheet.cssText = css;
  } else {
    // For other browsers
    styleElement.appendChild(document.createTextNode(css));
  }

  // Append the <style> element to the <head>
  document.head.appendChild(styleElement);
}

function waitForCSS() {
  if (document.styleSheets.length === 0) {
    // CSS is not yet loaded, call requestAnimationFrame to check again later
    requestAnimationFrame(waitForCSS);
  } else {
    showContent();
  }
}

// Call the waitForCSS function when the DOM is ready
document.addEventListener("DOMContentLoaded", () => {
  waitForCSS();
  var lightbox = GLightbox({
    selector: ".glightbox a",
  });
  const allLightboxes = document.querySelectorAll(".has-playbutton a");
  allLightboxes.forEach((element) => {
    const htmlContent =
      "<div class='absolute inset-0 z-10 pointer-events-none'><svg id='playbutton' class='absolute m-auto inset-0 w-16' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 512 512' xml:space='preserve'><path style='fill:#DB2B42;' d='M460.8,68.452H51.2c-28.16,0-51.2,23.04-51.2,51.2v272.696c0,28.16,23.04,51.2,51.2,51.2h409.6c28.16,0,51.2-23.04,51.2-51.2V119.652C512,91.492,488.96,68.452,460.8,68.452z M188.44,359.98V151.108l195.624,104.44L188.44,359.98z'/><polygon style='fill:#FFFFFF;' points='188.44,359.98 384.056,255.548 188.44,151.108 '/></svg></div>";

    // Use insertAdjacentHTML to insert content before the selected element
    element.insertAdjacentHTML("beforebegin", htmlContent);
  });
});

document.addEventListener("visibilitychange", () => {
  loadingScreen.classList.remove("in-from-right");
  loadingScreen.classList.remove("loaded");
});

window.addEventListener("beforeunload", () => {
  loadingScreen.classList.remove("loaded");
  loadingScreen.classList.add("in-from-right");
  loadingScreen.classList.add("loaded");
  setTimeout(function () {}, 1000);
});
