export default class ConsentHelper{
    constructor(){
      this.ckyConsentCont = document.querySelector(".cky-consent-container");
        this.events();
      }
      
      events(){
        if(this.ckyConsentCont){
          const rejectBtn = this.ckyConsentCont.querySelector(".cky-btn-reject");
          if(rejectBtn){
            rejectBtn.addEventListener("click", this.removeCookies);
          }
          };
        }


   removeCookies = (e)=> {
    // Retrieve cookies
      const cookies = document.cookie.split(';');

      // Loop through each cookie
          cookies.forEach((cookie) => {
            // Check if the cookie contains the substring "_ga"
            if (cookie.includes("_ga")) {
              // Remove the "_ga" cookie by setting its expiry date to a date in the past
              document.cookie = `${cookie.split('=')[0]}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.pixelfolio.org`;
              console.log( `${cookie.split('=')[0]}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.pixelfolio.org`)
            }
          });
   }


}

    