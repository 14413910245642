import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

export default class SlideShowScrubber {
  constructor() {
    this.isHomepage = document.querySelector("body.home");
    this.device;
    // this.screens = document.querySelector(".screens");
    this.screen;
    this.slideShow = document.querySelector(".glide__slides");
    this.slides = document.querySelectorAll(".scrubber-slider__slide");
    this.textWrapper = document.querySelector(".text-wrapper");

    this.events();
  }

  events() {
    if (this.isHomepage) this.initiateSlider();
  }

  initiateSlider = () => {
    gsap.defaults({ ease: "linear" });
    gsap.registerPlugin(ScrollTrigger);
    // gsap.defaults({ ease: "inOut" });

    let mm = gsap.matchMedia();

    mm.add(
      {
        isMobile: "(max-width: 490px)",
        isTablet: "(max-width: 1023px)",
        isDesktop: "(min-width: 1024px)",
        isGigantic: "(min-width: 1980px)",
      },
      (context) => {
        let { isMobile, isTablet, isDesktop, isGigantic } = context.conditions;

        let sections = gsap.utils.toArray(this.slides);

        this.slideShow.style.overflow = "visible";

        if (isDesktop) this.slideShow.style.marginLeft = "40vw";

        if (isGigantic) this.slideShow.style.marginLeft = "45vw";

        if (isTablet) this.slideShow.style.marginLeft = "35vw";

        if (isMobile) this.slideShow.style.marginLeft = "25vw";

        // gsap.timeline({scrollTrigger:{
        //   trigger:".footer",
        //   // start: "300px bottom",
        //   start: "top bottom",
        //   end:"300px top",
        //   scrub: true,
        // }})

        // .to(navbarMenuContent, { opacity: 0.9}, 0)
        // .fromTo("#logo", {y:0,scale:1}, { y: window.innerHeight/2.2,  scale: isMobile ? 2 : 3}, 0)
        //  gsap.to(".wp-block-cover", {opacity:1, delay: isOnTop ? 1.5 : 0})
        let scrollTween = gsap.to(sections, {
          xPercent: -100 * (sections.length - 1),
          ease: "none", // <-- IMPORTANT!
          scrollTrigger: {
            trigger: ".hero-slider",
            start: "center center",
            pin: true,
            markers: false,
            scrub: 0.1,
            //snap: directionalSnap(1 / (sections.length - 1)),
            end: "+=3000",
          },
        });

        sections.forEach((slide, id) => {
          ScrollTrigger.create({
            trigger: slide,
            containerAnimation: scrollTween,
            onToggle: (self) => {
              if (self.isActive) {
                document
                  .querySelectorAll(".scrubber-slider__slide")
                  .forEach((element) =>
                    element.classList.remove("shadow-lg", "bg-white")
                  );
                self.trigger.classList.add("shadow-lg", "bg-white");
                document
                  .querySelectorAll(".scrubber__slide--active")
                  .forEach((element) =>
                    element.classList.remove(
                      "scrubber__slide--active",
                      "!pointer-events-auto"
                    )
                  );

                document
                  .querySelector(`.slide-${id}`)
                  .classList.add(
                    "scrubber__slide--active",
                    "!pointer-events-auto"
                  );
              }
            },
            toggleClass: "scrubber__slide--active",
            start: isMobile
              ? "center 72%"
              : isGigantic
              ? "center 55%"
              : "center 60%",
            end: isMobile
              ? "center 28%"
              : isGigantic
              ? "center 45%"
              : "center 40%",
            scrub: 0.5,
            id,
          });

          const slideText = document.createElement("p");
          id == 0 && slide.classList.add("shadow-lg", "bg-white");

          slideText.classList.add(
            `slide-${id}`,
            "w-fit",
            "absolute",
            "inset-0",
            "h-7",
            "max-w-full",
            "text-center",
            "m-auto",
            "text-color-slideshow",
            "font-normal",
            "text-2xl",
            "!leading-6",
            "lg:text-3xl",
            "overflow-hidden",
            id == 0 ? "scrubber__slide--active" : "pointer-events-none"
          );
          const span = `<a href="${slide.dataset.link}"><span class="w-full truncate">${slide.dataset.text}</span></a>`;
          slideText.insertAdjacentHTML("beforeend", span);
          this.textWrapper.appendChild(slideText);
        });
      }
    );
  };
}
