import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

export default class AnimationLogo {
  constructor() {
    this.isHomepage = document.querySelector("body.home");
    this.atfSection = document.querySelector(".atf");
    this.navBar = document.getElementById("navbar");
    this.scrollIndicator;
    this.logo = document.getElementById("logo");
    this.isOnTop = window.pageYOffset == 0;
    this.events();
  }

  events() {
    if (this.atfSection?.classList.contains("has-scroll-indicator")) {
      this.atfSection.insertAdjacentHTML(
        "beforeend",
        `
          <div class="container_mouse">
              <span class="mouse-btn">
                  <span class="mouse-scroll"></span>
              </span>
          </div>
        `
      );
      this.scrollIndicator = document.querySelector(".container_mouse");
    }
    if (this.isHomepage && this.atfSection) this.initiateGSAP();
    if (this.isHomepage && !this.atfSection)
      gsap.to(this.navBar, { opacity: 1 }), gsap.to(this.logo, { opacity: 1 });
  }

  initiateGSAP = () => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.defaults({ ease: "inOut" });
    gsap.to(this.navBar, { opacity: 1, delay: 0.3 });
    gsap.to(this.scrollIndicator, { opacity: 1, delay: 2 });
    // this.isOnTop
    //   ? gsap.fromTo(
    //       this.logo,
    //       { scale: 8 },
    //       { scale: isMobile ? 2 : 3, opacity: 1, delay: 0.3 }
    //     )
    // : gsap.to(this.logo, { opacity: 1, delay: 0.3 });
    gsap.to(this.logo, { opacity: 1 });
    let mm = gsap.matchMedia();

    mm.add(
      {
        isMobile: "(max-width: 767px)",
        isTablet: "(min-width: 490px)",
        isDesktop: "(min-width: 767px)",
      },
      (context) => {
        let { isMobile, isDesktop } = context.conditions;

        // gsap.timeline({scrollTrigger:{
        //   trigger:".footer",
        //   // start: "300px bottom",
        //   start: "top bottom",
        //   end:"300px top",
        //   scrub: true,
        // }})

        // .to(navbarMenuContent, { opacity: 0.9}, 0)
        // .fromTo("#logo", {y:0,scale:1}, { y: window.innerHeight/2.2,  scale: isMobile ? 2 : 3}, 0)
        //  gsap.to(".wp-block-cover", {opacity:1, delay: isOnTop ? 1.5 : 0})
        gsap
          .timeline({
            scrollTrigger: {
              trigger: "window",
              invalidateOnRefresh: true,
              //start: -navBar.offsetHeight + "px" + " top",
              end: `${this.atfSection.offsetHeight / 2}px top`,
              toggleActions: "restart none none reverse",
              scrub: 0.5,
              //markers:true,
            },
          })

          .fromTo(
            this.logo,
            {
              y: isMobile
                ? (this.atfSection.offsetHeight - 50) / 2
                : this.atfSection.offsetHeight / 2,
              scale: isMobile ? 2 : 3,
            },
            { y: 0, scale: 1 },
            0
          )
          .to(
            this.scrollIndicator,
            {
              opacity: 0,
            },
            0
          );
        gsap.to(".atf", { opacity: 1, delay: this.isOnTop ? 0.5 : 0 });
      }
    );
  };
}
